<template>
  <v-container>
    <div><h1>Пользователи</h1></div>
    <p>Пока не понял зачем нужна эта страница</p>
  </v-container>
</template>

<script>
export default {
  name: "Users",
  metaInfo: {
    title: "Пользователи",
  },
};
</script>

<style></style>
